<!--
 * @Description: 车辆用户管理系统  平台用户统计 cuPlateUserStatistic
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-03-31 08:59:43
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <el-row class="lineOne">停车用户统计分析</el-row>
    <el-row class="lineTwo">
      <el-row class="lineTwoLeft">
        <el-row class="inlineOne">

          <el-row class="item"
                  style="background: linear-gradient(93deg, #E0BAF7 0%, #BF79E9 100%)">
            <el-row class="itemleft">
              <el-row class="itemleftOne">活跃用户</el-row>
              <el-row class="itemleftTwo">159921</el-row>
            </el-row>
            <el-row class="itemright">
              <img src="@/assets/newStatistictIcon/cuPlateUserStatisticOne.png"
                   alt="">
            </el-row>
          </el-row>

          <el-row class="item"
                  style="background: linear-gradient(92deg, #44E3F9 0%, #1198B3 100%)">
            <el-row class="itemleft">
              <el-row class="itemleftOne">今日在线</el-row>
              <el-row class="itemleftTwo">99921</el-row>
            </el-row>
            <el-row class="itemright">
              <img src="@/assets/newStatistictIcon/cuPlateUserStatisticTwo.png"
                   alt="">
            </el-row>
          </el-row>

          <el-row class="item"
                  style="background: linear-gradient(89deg, #40AAE6 0%, #147AB3 100%)">
            <el-row class="itemleft">
              <el-row class="itemleftOne">绑定车辆</el-row>
              <el-row class="itemleftTwo">313316</el-row>
            </el-row>
            <el-row class="itemright">
              <img src="@/assets/newStatistictIcon/cuPlateUserStatisticThree.png"
                   alt="">
            </el-row>
          </el-row>

          <el-row class="item"
                  style="background: linear-gradient(93deg, #A8AEFF 0%, #2548F2 100%)">
            <el-row class="itemleft">
              <el-row class="itemleftOne">绑定比例</el-row>
              <el-row class="itemleftTwo">89%</el-row>
            </el-row>
            <el-row class="itemright">
              <img src="@/assets/newStatistictIcon/cuPlateUserStatisticFour.png"
                   alt="">
            </el-row>
          </el-row>

          <el-row class="item"
                  style="margin-right:0;background: linear-gradient(90deg, #44E2F9 0%, #1199B4 100%)">
            <el-row class="itemleft">
              <el-row class="itemleftOne">一户多车比例</el-row>
              <el-row class="itemleftTwo">12%</el-row>
            </el-row>
            <el-row class="itemright">
              <img src="@/assets/newStatistictIcon/cuPlateUserStatisticFive.png"
                   alt="">
            </el-row>
          </el-row>
        </el-row>

        <el-row class="inlineTwo">
          <el-row class="inlineTwoLeft">
            <el-row class="title">各渠道用户停车时长分析</el-row>
            <el-row class="chartsDiv"
                    id="leftTopLeft"
                    :option="leftTopLeftOptions">
            </el-row>
          </el-row>
          <el-row class="inlineTwoRight">
            <el-row class="title">用户年龄分布</el-row>
            <el-row class="chartsDiv"
                    id="leftTopRight"
                    :option="leftTopRightOptions">
            </el-row>
          </el-row>
        </el-row>
        <el-row class="inlineThree">
          <el-row class="title">用户年龄分布</el-row>
          <el-row class="chartsDiv"
                  id="leftBottom"
                  :option="leftBottomOptions">
          </el-row>
        </el-row>
      </el-row>
      <el-row class="lineTwoRight">
        <el-row class="inLine">
          <el-row class="title">各渠道用户注册占比</el-row>
          <el-row class="chartsDiv"
                  id="rightTop"
                  :option="rightTopOptions">
          </el-row>
        </el-row>
        <el-row class="inLine">
          <el-row class="title">一户多车占比</el-row>
          <el-row class="chartsDiv"
                  id="rightMiddle"
                  :option="rightMiddleOptions">
          </el-row>
        </el-row>
        <el-row class="inLine">
          <el-row class="title">无感绑定车辆占比</el-row>
          <el-row class="chartsDiv"
                  id="rightBottom"
                  :option="rightBottomOptions">
          </el-row>
        </el-row>
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import * as echarts from 'echarts';
import { mapState } from 'vuex'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      colorJson: {
        dark: {
          labelColor: "#ffffff"
        },
        light: {
          labelColor: "#000000"
        }
      },

      leftTopLeft: "",
      leftTopLeftOptions: {},

      leftTopRight: "",
      leftTopRightOptions: {},

      leftBottom: "",
      leftBottomOptions: {},

      rightTop: "",
      rightTopOptions: {},

      rightMiddle: "",
      rightMiddleOptions: {},

      rightBottom: "",
      rightBottomOptions: {}
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {
    theme () {
      this.initCharts()
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initCharts()
  },
  //方法集合
  methods: {
    initCharts () {
      this.queryLeftTopLeftCharts()
      this.queryLeftTopRightCharts()
      this.queryLeftBottomCharts()
      this.queryRightTopCharts()
      this.queryRightMiddleCharts()
      this.queryRightBottomCharts()
    },
    queryLeftTopLeftCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('leftTopLeft'));
      let indicatorData = [
        {
          name: '5元以下',
          max: 100
        },
        {
          name: '6-10元',
          max: 100
        },
        {
          name: '11-15元',
          max: 100
        },
        {
          name: '16-20元',
          max: 100
        },
        {
          name: '21-25元',
          max: 100
        },
        {
          name: '25元以上',
          max: 100
        }
      ];
      let dataValue1 = [80, 80, 80, 70, 60, 50]
      let dataValue2 = [40, 70, 50, 60, 30, 80]
      myChart.setOption({
        backgroundColor: '',
        color: ['#25C87F', '#2F50F3'],
        tooltip: {
          show: true
        },
        radar: {
          center: ['50%', '50%'], // 外圆的位置
          radius: '65%',
          name: {
            textStyle: {
              fontFamily: 'PingFangSC-Regular,PingFang SC',
              fontStyle: 'normal',
              color: labelColor,
              fontSize: 16,
              fontWeight: 400,
            }
          },
          indicator: indicatorData,
          splitArea: {
            show: false,
            areaStyle: {
              // 分隔区域的样式设置。
              color: ['']
            }
          },
          axisLine: {
            // 指向外圈文本的分隔线样式
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'solid',
              color: '#00487A', // 分隔线颜色
              width: 1 // 分隔线线宽
            }
          }
        },
        series: [
          {
            type: 'radar',
            symbolSize: 5,
            data: [
              {
                value: dataValue1,
                name: '微信',
                areaStyle: {
                  normal: {
                    color: {
                      type: 'radial',
                      x: 0.5,
                      y: 0.5,
                      r: 0.5,
                      colorStops: [],
                      global: false
                    }
                  }
                },
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 1
                    },
                    opacity: 0.3
                  },
                  emphasis: {
                    lineStyle: {
                      width: 5
                    },
                    opacity: 0
                  }
                },
              },
              {
                value: dataValue2,
                name: '支付宝',
                areaStyle: {
                  normal: {
                    color: {
                      type: 'radial',
                      x: 0.5,
                      y: 0.5,
                      r: 0.5,
                      colorStops: [
                      ],
                      global: false // 缺省为 false
                    }
                  }
                },
                itemStyle: { // 折线拐点标志的样式。
                  normal: { // 普通状态时的样式
                    lineStyle: {
                      width: 1
                    },
                    opacity: 0.3
                  },
                  emphasis: { // 高亮时的样式
                    lineStyle: {
                      width: 5
                    },
                    opacity: 0
                  }
                },
              }
            ]
          }
        ]
      })
    },
    queryLeftTopRightCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('leftTopRight'));
      let indicatorData = [
        {
          name: '25-30岁',
          max: 100
        },
        {
          name: '31-35岁',
          max: 100
        },
        {
          name: '35-40岁',
          max: 100
        },
        {
          name: '41-45岁',
          max: 100
        },
        {
          name: '46岁以上',
          max: 100
        },
        {
          name: '24岁以下',
          max: 100
        }
      ]
      let dataValue = [40, 70, 50, 60, 30, 80]
      myChart.setOption({
        backgroundColor: '',
        color: ['#F65416'],
        tooltip: {
          show: true
        },
        radar: {
          center: ['50%', '50%'], // 外圆的位置
          radius: '65%',
          name: {
            textStyle: {
              fontFamily: 'PingFangSC-Regular,PingFang SC',
              fontStyle: 'normal',
              color: labelColor,
              fontSize: 16,
              fontWeight: 400,
            }
          },
          indicator: indicatorData,
          splitArea: {
            show: false,
            areaStyle: {
              // 分隔区域的样式设置。
              color: ['']
            }
          },
          axisLine: {
            // 指向外圈文本的分隔线样式
            show: false,
            lineStyle: {
              color: ''
            }
          },
          splitLine: {
            lineStyle: {
              type: 'solid',
              color: '#00487A', // 分隔线颜色
              width: 1 // 分隔线线宽
            }
          }
        },
        series: [
          {
            type: 'radar',
            symbolSize: 5,
            data: [
              {
                value: dataValue,
                name: '年龄',
                areaStyle: {
                  normal: {
                    color: {
                      type: 'radial',
                      x: 0.5,
                      y: 0.5,
                      r: 0.5,
                      colorStops: [
                      ],
                      global: false // 缺省为 false
                    }
                  }
                },
                itemStyle: { // 折线拐点标志的样式。
                  normal: { // 普通状态时的样式
                    lineStyle: {
                      width: 1
                    },
                    opacity: 0.3
                  },
                  emphasis: { // 高亮时的样式
                    lineStyle: {
                      width: 5
                    },
                    opacity: 0
                  }
                },
              }
            ]
          }
        ]
      })
    },
    queryLeftBottomCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      let myChart = echarts.init(document.getElementById('leftBottom'));
      myChart.setOption({
        backgroundColor: '',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(0, 255, 233,0)'
                }, {
                  offset: 0.5,
                  color: 'rgba(255, 255, 255,1)',
                }, {
                  offset: 1,
                  color: 'rgba(0, 255, 233,0)'
                }],
                global: false
              }
            },
          },
        },
        legend: {
          show: true,
          align: "left",
          top: '5%',
          right: '2%',
          type: 'plain',
          textStyle: {
            color: labelColor,
            fontSize: 12
          },
          icon: 'circle',
          itemGap: 25,
          itemWidth: 10
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '2%',
          bottom: '10%',
          // containLabel: true
        },
        xAxis: [{
          type: 'category',
          axisLine: {
            show: false,
            color: 'red',
            lineStyle: {
              type: 'solid',
              color: '#00487A',//左边线的颜色
              width: '2'//坐标线的宽度
            }
          },
          axisLabel: {
            color: labelColor,
            width: 100
          },
          splitLine: {
            show: false
          },
          boundaryGap: false,
          data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
        }],
        yAxis: [{
          type: 'value',
          min: 0,
          splitNumber: 4,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#00487A',
              opacity: 1
            }
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            margin: 20,
            textStyle: {
              color: labelColor,
            },
          },
          axisTick: {
            show: false,
          },
        }],
        series: [
          {
            name: '已注册用户',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              normal: {
                color: "#FFA000",
              },
            },
            itemStyle: {
              color: "#ffffff",
              borderColor: "#FFA000",
              borderWidth: 2,
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(255, 179, 50, 1)'
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 179, 50, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 179, 50, 0)'
                  }
                ], false),
              }
            },
            data: [400, 450, 500, 550, 600, 650, 700, 750, 700, 650, 600, 550]
          },
          {
            name: '注销用户',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              normal: {
                color: "#193AD6",
              },
            },
            itemStyle: {
              color: "#fff",
              borderColor: "#193AD6",
              borderWidth: 2,
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(43, 77, 243,1)'
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(43, 77, 243,0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(43, 77, 243,0)'
                  }
                ], false),
              }
            },
            data: [300, 350, 400, 450, 500, 550, 600, 650, 600, 550, 500, 450]
          },
          {
            name: '注册用户',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              normal: {
                color: "#871E8D",
              },
            },
            itemStyle: {
              color: "#fff",
              borderColor: "#871E8D",
              borderWidth: 2,
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(135, 30, 141,1)'
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(135, 30, 141,0.6)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(135, 30, 141,0)'
                  }
                ], false),
              }
            },
            data: [100, 150, 200, 250, 300, 350, 400, 450, 400, 350, 300, 250]
          },
        ]
      })
    },
    queryRightTopCharts () {
      let labelColor = this.colorJson[this.theme].labelColor
      // 修改3d饼图绘制过程
      let each = window.Highcharts.each,
        round = Math.round,
        cos = Math.cos,
        sin = Math.sin,
        deg2rad = Math.deg2rad;
      window.Highcharts.wrap(window.Highcharts.seriesTypes.pie.prototype, 'translate', function (proceed) {
        proceed.apply(this, [].slice.call(arguments, 1));
        if (!this.chart.is3d()) {
          return;
        }
        let series = this,
          chart = series.chart,
          options = chart.options,
          seriesOptions = series.options,
          depth = seriesOptions.depth || 0,
          options3d = options.chart.options3d,
          alpha = options3d.alpha,
          beta = options3d.beta,
          z = seriesOptions.stacking ? (seriesOptions.stack || 0) * depth : series._i * depth
        z += depth / 2;
        if (seriesOptions.grouping !== false) {
          z = 0;
        }
        each(series.data, function (point) {
          var shapeArgs = point.shapeArgs,
            angle;
          point.shapeType = 'arc3d';
          var ran = point.options.h;
          shapeArgs.z = z;
          shapeArgs.depth = depth * 0.75 + ran;
          shapeArgs.alpha = alpha;
          shapeArgs.beta = beta;
          shapeArgs.center = series.center;
          shapeArgs.ran = ran;
          angle = (shapeArgs.end + shapeArgs.start) / 2;
          point.slicedTranslation = {
            translateX: round(cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)),
            translateY: round(sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad))
          };
        });
      });
      (function (H) {
        H.wrap(window.Highcharts.SVGRenderer.prototype, 'arc3dPath', function (proceed) {
          var ret = proceed.apply(this, [].slice.call(arguments, 1));
          ret.zTop = (ret.zOut + 0.5) / 100;
          return ret;
        });
      }(window.Highcharts));
      this.rightTopOptions = {
        chart: {
          type: 'pie',
          renderTo: "rightTop",
          backgroundColor: "rgba(0,0,0,0)",
          marginLeft: 10,
          marginTop: 10,
          options3d: {
            enabled: true,
            alpha: 60,
          },
          events: {
            load: function () {
              var each = window.Highcharts.each,
                points = this.series[0].points;
              each(points, function (p) {
                p.graphic.attr({
                  translateY: -p.shapeArgs.ran
                });
                p.graphic.side1.attr({
                  translateY: -p.shapeArgs.ran
                });
                p.graphic.side2.attr({
                  translateY: -p.shapeArgs.ran
                });
              });
            }
          }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        colors: ["#E9C503", "#6683E3", "#E0BAF7", "#44E2F9"],
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: 'pointer',
            depth: 20,
            innerSize: 100,
            showInLegend: false,
            dataLabels: {
              enabled: true,
              color: labelColor
            }
          }
        },

        legend: {
          enabled: false
        },
        series: [{
          type: 'pie',
          name: '',
          data: [
            {
              name: '安卓用户',
              y: 10,
              h: 2
            }, {
              name: '微信用户',
              y: 15,
              h: 10
            }, {
              name: '支付宝用户',
              y: 10,
              h: 10
            }, {
              name: 'IOS用户',
              y: 25,
              h: 10
            }
          ]
        }]
      };
      new window.Highcharts.chart(this.rightTopOptions);
    },
    queryRightMiddleCharts () {
      let labelColor = this.colorJson[this.theme].labelColor

      // 修改3d饼图绘制过程
      let each = window.Highcharts.each,
        round = Math.round,
        cos = Math.cos,
        sin = Math.sin,
        deg2rad = Math.deg2rad;
      window.Highcharts.wrap(window.Highcharts.seriesTypes.pie.prototype, 'translate', function (proceed) {
        proceed.apply(this, [].slice.call(arguments, 1));
        if (!this.chart.is3d()) {
          return;
        }
        let series = this,
          chart = series.chart,
          options = chart.options,
          seriesOptions = series.options,
          depth = seriesOptions.depth || 0,
          options3d = options.chart.options3d,
          alpha = options3d.alpha,
          beta = options3d.beta,
          z = seriesOptions.stacking ? (seriesOptions.stack || 0) * depth : series._i * depth
        z += depth / 2;
        if (seriesOptions.grouping !== false) {
          z = 0;
        }
        each(series.data, function (point) {
          var shapeArgs = point.shapeArgs,
            angle;
          point.shapeType = 'arc3d';
          var ran = point.options.h;
          shapeArgs.z = z;
          shapeArgs.depth = depth * 0.75 + ran;
          shapeArgs.alpha = alpha;
          shapeArgs.beta = beta;
          shapeArgs.center = series.center;
          shapeArgs.ran = ran;
          angle = (shapeArgs.end + shapeArgs.start) / 2;
          point.slicedTranslation = {
            translateX: round(cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)),
            translateY: round(sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad))
          };
        });
      });
      (function (H) {
        H.wrap(window.Highcharts.SVGRenderer.prototype, 'arc3dPath', function (proceed) {
          var ret = proceed.apply(this, [].slice.call(arguments, 1));
          ret.zTop = (ret.zOut + 0.5) / 100;
          return ret;
        });
      }(window.Highcharts));
      this.rightMiddleOptions = {
        chart: {
          type: 'pie',
          renderTo: "rightMiddle",
          backgroundColor: "rgba(0,0,0,0)",
          marginLeft: 10,
          marginTop: 10,
          options3d: {
            enabled: true,
            alpha: 60,
          },
          events: {
            load: function () {
              var each = window.Highcharts.each,
                points = this.series[0].points;
              each(points, function (p) {
                p.graphic.attr({
                  translateY: -p.shapeArgs.ran
                });
                p.graphic.side1.attr({
                  translateY: -p.shapeArgs.ran
                });
                p.graphic.side2.attr({
                  translateY: -p.shapeArgs.ran
                });
              });
            }
          }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        colors: ["#E9C503", "#6683E3", "#E0BAF7", "#44E2F9"],
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: 'pointer',
            depth: 20,
            innerSize: 100,
            showInLegend: false,
            dataLabels: {
              enabled: true,
              color: labelColor
            }
          }
        },
        legend: {
          enabled: false
        },
        series: [{
          type: 'pie',
          name: '',
          data: [
            {
              name: '无车',
              y: 10,
              h: 2
            }, {
              name: '一车',
              y: 15,
              h: 4
            }
            , {
              name: '二车',
              y: 20,
              h: 6
            }, {
              name: '三车',
              y: 25,
              h: 10
            }]
        }]
      };
      new window.Highcharts.chart(this.rightMiddleOptions);
    },
    queryRightBottomCharts () {
      let labelColor = this.colorJson[this.theme].labelColor

      // 修改3d饼图绘制过程
      let each = window.Highcharts.each,
        round = Math.round,
        cos = Math.cos,
        sin = Math.sin,
        deg2rad = Math.deg2rad;
      window.Highcharts.wrap(window.Highcharts.seriesTypes.pie.prototype, 'translate', function (proceed) {
        proceed.apply(this, [].slice.call(arguments, 1));
        if (!this.chart.is3d()) {
          return;
        }
        let series = this,
          chart = series.chart,
          options = chart.options,
          seriesOptions = series.options,
          depth = seriesOptions.depth || 0,
          options3d = options.chart.options3d,
          alpha = options3d.alpha,
          beta = options3d.beta,
          z = seriesOptions.stacking ? (seriesOptions.stack || 0) * depth : series._i * depth
        z += depth / 2;
        if (seriesOptions.grouping !== false) {
          z = 0;
        }
        each(series.data, function (point) {
          var shapeArgs = point.shapeArgs,
            angle;
          point.shapeType = 'arc3d';
          var ran = point.options.h;
          shapeArgs.z = z;
          shapeArgs.depth = depth * 0.75 + ran;
          shapeArgs.alpha = alpha;
          shapeArgs.beta = beta;
          shapeArgs.center = series.center;
          shapeArgs.ran = ran;
          angle = (shapeArgs.end + shapeArgs.start) / 2;
          point.slicedTranslation = {
            translateX: round(cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)),
            translateY: round(sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad))
          };
        });
      });
      (function (H) {
        H.wrap(window.Highcharts.SVGRenderer.prototype, 'arc3dPath', function (proceed) {
          var ret = proceed.apply(this, [].slice.call(arguments, 1));
          ret.zTop = (ret.zOut + 0.5) / 100;
          return ret;
        });
      }(window.Highcharts));
      this.rightBottomOptions = {
        chart: {
          type: 'pie',
          renderTo: "rightBottom",
          backgroundColor: "rgba(0,0,0,0)",
          marginLeft: 10,
          marginTop: 10,
          options3d: {
            enabled: true,
            alpha: 60,
          },
          events: {
            load: function () {
              var each = window.Highcharts.each,
                points = this.series[0].points;
              each(points, function (p) {
                p.graphic.attr({
                  translateY: -p.shapeArgs.ran
                });
                p.graphic.side1.attr({
                  translateY: -p.shapeArgs.ran
                });
                p.graphic.side2.attr({
                  translateY: -p.shapeArgs.ran
                });
              });
            }
          }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        colors: ["#E9C503", "#6683E3"],
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: 'pointer',
            depth: 20,
            innerSize: 100,
            showInLegend: false,
            dataLabels: {
              enabled: true,
              color: labelColor
            }
          }
        },
        legend: {},
        series: [{
          type: 'pie',
          name: '',
          data: [
            {
              name: '微信',
              y: 25.8,
              h: 2
            }, {
              name: '支付宝',
              y: 30.0,
              h: 10
            }]
        }]
      };
      new window.Highcharts.chart(this.rightBottomOptions);
    },
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.mainbody {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .lineOne {
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 37px;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    @include font_color('chartsTitleColor');
    @include background('chartsTitleBack');
  }
  .lineTwo {
    width: 100%;
    height: 91%;
    margin-top: 1%;
    display: flex;
    .lineTwoLeft {
      width: 75%;
      height: 100%;
      .inlineOne {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: space-around;
        .item {
          width: 24%;
          display: flex;
          margin-right: 1%;
          .itemleft {
            width: 70%;
            text-align: center;
            color: #ffffff;
            .itemleftOne {
              height: 50%;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              line-height: 50px;
            }
            .itemleftTwo {
              height: 50%;
              line-height: 40px;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
            }
          }
          .itemright {
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 52px;
              height: 52px;
            }
          }
        }
      }
      .inlineTwo {
        width: 100%;
        height: 40%;
        margin-top: 1%;
        display: flex;
        .inlineTwoLeft {
          width: 49.5%;
          @include background('chartsBack');
          .title {
            width: 100%;
            height: 30px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 26px;
            @include font_color('chartsTitleColor');
            opacity: 1;
            padding-top: 1%;
            padding-left: 3.7%;
          }
          .chartsDiv {
            width: 100%;
            height: 90%;
          }
        }
        .inlineTwoRight {
          width: 49.5%;
          @include background('chartsBack');
          margin-left: 1%;
          .title {
            width: 100%;
            height: 30px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 26px;
            @include font_color('chartsTitleColor');
            opacity: 1;
            padding-top: 1%;
            padding-left: 3.7%;
          }
          .chartsDiv {
            width: 100%;
            height: 90%;
          }
        }
      }
      .inlineThree {
        width: 100%;
        height: 46%;
        margin-top: 1%;
        @include background('chartsBack');
        .title {
          width: 100%;
          height: 30px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 26px;
          @include font_color('chartsTitleColor');
          opacity: 1;
          padding-top: 0.5%;
          padding-left: 2.1%;
        }
        .chartsDiv {
          width: 100%;
          height: 90%;
        }
      }
    }
    .lineTwoRight {
      width: 24%;
      height: 100%;
      margin-left: 1%;
      @include background('chartsBack');
      .inLine {
        width: 100%;
        height: 32%;
        margin-top: 2%;
        .title {
          width: 100%;
          height: 30px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 26px;
          @include font_color('chartsTitleColor');
          opacity: 1;
          padding-top: 0.5%;
          padding-left: 2.1%;
        }
        .chartsDiv {
          width: 100%;
          height: 90%;
        }
      }
    }
  }
}
</style>